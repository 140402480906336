import './App.css';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './home/home.js';
import Page from './page_404/Page.js';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='*' element={<Page/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;