import "./style.css";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import tv from "./tv.png";

export default function Page404() {

    return (
        <div className="container">
            <LazyLoadImage src={tv} className="tv" alt="tv img" height="50%" width="50%" />
            <p className="text">Página não encontrada</p>
            <button className="button" onClick={() => window.location.href = "/"}>Voltar para a home</button>

        </div>
    );
}