import "./style.css"
import ParticlesBackgroud from "../components/ParticlesBackgroud.tsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import js from "../imgs/js.png"
import c from "../imgs/c.png"
import git from "../imgs/git.png"
import r from "../imgs/react.png"

import rc from "../imgs/compressed/react-compressed.webp"
import jsc from "../imgs/compressed/js-compressed.webp"
import gitc from "../imgs/compressed/git-compressed.webp"
import cc from "../imgs/compressed/c-compressed.webp"

export default function Home() {
    return (
            
            <div className="container">
                <ParticlesBackgroud/>
                <link rel="icon" type="image/x-icon" href="/src/images/icon.ico"></link>
                <div className="parent row">
                    
                    <section id="home" className="home">
                        <p className="apresentacao">Eu sou o <span className="nome">Guilherme.</span></p>
                        <p className="subtitle">Um estudante que está atualmente aprendendo:</p>
                        <div className="imgsrow">
                            <div className="column">
                                <LazyLoadImage className="c" src={c} placeholderSrc={cc} />
                                 {/* <img className="c" src="https://static-00.iconduck.com/assets.00/c-sharp-c-icon-456x512-9sej0lrz.png" loading="lazy" alt="c icon" width="100%"/> */}
                            </div>
                            <div className="column">
                                <LazyLoadImage className="git" src={git} placeholderSrc={gitc} />
                                {/*<img className="git" src="https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png" loading="lazy" alt="c icon" width="100%"/> */}
                            </div>
                            <div className="column">
                            <LazyLoadImage className="java" src={js} placeholderSrc={jsc} />
                                {/*<img className="java" src="https://roufid.com/wp-content/uploads/2019/02/1024.png" loading="lazy" alt="javascript icon" width="100%"/> */}
                            </div>
                            <div className="column">
                            <LazyLoadImage className="react" src={r} placeholderSrc={rc} />
                                {/*<img className="react" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png" loading="lazy" alt="react native icon" width="100%"/> */}
                            </div>
                            
                        </div>
                        
                    </section>
                    
                    <section id="project" className="project">
                        <div className="project-title">
                            <p className="project-title-text">Projetos</p>
                        </div>
                        <div className="project-content">
                            <p className="project-content-text">Em breve...</p>
                        </div>

                    </section>
                </div>
                <nav>
                    <ul className="navigation">
                        <li onClick={() => document.getElementById('home').scrollIntoView() } style={{cursor:'pointer'}}></li>
                        
                        <li onClick={() => document.getElementById('project').scrollIntoView()} style={{cursor:'pointer'}}></li>
                    </ul>
                </nav>
                
            </div>
    );
    
}

